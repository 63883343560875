<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Department Inventory Stocks</span>
          <v-spacer></v-spacer>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="assetId"
                  :items="assetList"
                  outlined
                  item-text="name"
                  item-value="_id"
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Assets"
                  clearable
                >
                </v-select>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-autocomplete
                  v-model="employeeId"
                  :items="empList"
                  item-text="name"
                  :search-input.sync="search1"
                  item-value="_id"
                  clearable
                  outlined
                  hide-details
                  label="Employee"
                  class="rounded-xl"
                  dense
                ></v-autocomplete>
              </v-flex>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="Fstatus"
                  :items="statusList"
                  outlined
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Status"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="fType"
                  :items="typeList"
                  outlined
                  class="rounded-xl"
                  dense
                  hide-details
                  clearable
                  label="Type"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="inventoryList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Asset Code</th>
                    <!-- <th class="text-left">Quantity</th> -->
                    <th class="text-left">Assign Type</th>
                    <th class="text-left">Department</th>
                    <th class="text-left">Assigned To</th>
                    <th class="text-left">Project</th>
                    <th class="text-left" v-if="appType == 'admin'">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in inventoryList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.assetId.name"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <router-link
                          :to="
                            '/Assets/ViewLog?id=' +
                            item.assetId._id +
                            '&name=' +
                            item.assetId.name +
                            '&invid=' +
                            item._id
                          "
                        >
                          <span class="kumbhBold" style="color: #005f32">
                            {{ item.assetId.name }}
                          </span>
                        </router-link>
                      </span>
                    </td>
                    <td>
                      <span
                        v-if="item.code"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.code }}</span
                      >
                      <span v-else> 0 </span>
                    </td>
                    <!-- <td>
                      <span
                        v-if="item.quantity"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.quantity }}</span
                      >
                    </td> -->

                    <td>
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.assignType }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.departmentId">
                          {{ item.departmentId.deptName }}
                        </span>

                        <span v-else> NA </span>
                      </span>
                    </td>
                    <td>
                      <!-- <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      > 
                          <span v-if="item.employeeId">
                            {{ item.employeeId.name }}
                          </span>
                       
                        <span v-else> NA </span>
                      </span> -->

                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.assignType == 'Department'">
                          <span v-if="item.departmentId">
                            {{ item.departmentId.deptName }}
                          </span>
                        </span>
                        <span v-else-if="item.assignType == 'Employee'">
                          <span v-if="item.employeeId">
                            {{ item.employeeId.name }}
                          </span>
                        </span>
                        <span v-else>
                          {{ item.assignType }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.projectId">
                          {{ item.projectId.projectName }}
                        </span>

                        <span v-else> - </span>
                      </span>
                    </td>
                    <td>
                      <v-layout wrap>
                        <v-flex
                          xs6
                          sm4
                          md2
                          pa-2
                          v-if="
                            item.assetId.assetType == 'Consumable' &&
                            item.assignType == 'Department' &&
                            item.status == 'Active'
                          "
                        >
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                style="font-family: kumbhBold"
                                outlined
                                depressed
                                dark
                                block
                                tile
                                v-on="on"
                                v-bind="attrs"
                                color="#005f32"
                              >
                                <span>Mark as consumed</span>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap pa-6>
                                <v-flex xs12 align-self-end pa-3>
                                  <span class="kumbhMedium"
                                    >The stock will be marked as consumed. Are
                                    you sure you want to proceed?</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                                <v-btn
                                  color="#005f32"
                                  outlined
                                  tile
                                  @click="markConsumed(item)"
                                  >Proceed</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="70%">
          <v-card tile>
            <v-layout wrap v-if="editingitem">
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Ressign Asset</span
                >
              </v-flex>
              <v-container>
                <v-layout wrap pa-4>
                  <v-flex
                    xs12
                    sm6
                    pa-2
                    align-self-center
                    text-left
                    v-if="editingitem.assetId"
                  >
                    <v-text-field
                      v-model="editingitem.assetId.name"
                      label="Asset"
                      outlined
                      dense
                      readonly
                      hide-details
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2 align-self-center text-left>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="
                      assignType == 'Department' || assignType == 'Employee'
                    "
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      @change="employeeId = null"
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee'"
                  >
                    <v-autocomplete
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      :search-input.sync="search1"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose employee"
                      dense
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="validateEdit(editingitem._id)"
              >
                Reassign
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import store from "./../../../store";

import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      inventoryList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      assignTypes: ["Not Assigned", "Department", "Employee"],
      statusList: ["Active", "Consumed", "Dismantled"],
      assignType: null,
      Fstatus: null,

      assignList: ["Department", "Employee"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,

      typeList: ["Consumable", "Nonconsumable", "Semiconsumable"],
      fType: null,
    };
  },
  components: {
    // ImageComp,
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },
  watch: {
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    assetId() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    Fstatus() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    // assignType() {
    //   if (this.currentPage > 1) this.currentPage = 1;
    //   this.getData();
    // },

    departmentId() {
      this.getEmp();
    },
    search1() {
      this.getEmp();
    },

    fType() {
      this.currentPage = 1;
      this.getData();
    },

    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getDept();
    this.getEmp();
    if (this.fromDate) {
      this.fromDate = null;
    }
    if (this.toDate) {
      this.toDate = null;
    }
    this.getData();
    this.getAssets();
  },

  methods: {
    getAssets() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.deptList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmp() {
      var exp = "";
      if (this.editingitem.employeeId) {
        exp = this.editingitem.employeeId._id;
      }
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          departmentId: localStorage.getItem("departmentId"),
          keyword: this.search1,
          except: exp,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/department/inventoryStocks/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          assetId: this.assetId,
          departmentId: localStorage.getItem("departmentId"),
          status: this.Fstatus,

          assetType: this.fType,
          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.inventoryList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editcat(item) {
      this.editingitem = item;

      this.getEmp();
      // if (item.departmentId) this.departmentId = item.departmentId._id;
      // if (item.employeeId) this.employeeId = item.employeeId._id;
      this.editdialog = true;
    },
    validateEdit() {
      if (this.quantity > this.editingitem.quantity) {
        this.msg =
          "Quantity should be less than or same as already assigned quantity!";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["inventoryStockId"] = this.editingitem._id;
      data["assignType"] = this.assignType;
      data["departmentId"] = this.departmentId;
      data["employeeId"] = this.employeeId;
      data["quantity"] = this.quantity;
      axios({
        url: "/asset/reassign/transfer",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
    },
    markConsumed(cat) {
      var data = {};
      data["inventoryStockId"] = cat._id;
      data["status"] = "Consumed";
      axios({
        url: "/asset/inventoryStock/change/status",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Stock is marked as consumed";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
